:root {
  --white: #fff;
  --black: #000;
  --grey-300: rgb(89, 89, 89);
  --yellow-300: #dffe00;
}
:root {
  --primary-color: var(--white);
  --mode-color: var(--yellow-300);

}
