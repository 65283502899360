@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Bogart';
  src: url('../fonts/Bogart-Light-trial.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bogart';
  src: url('../fonts/Bogart-Regular-trial.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bogart';
  src: url('../fonts/Bogart-Medium-trial.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bogart';
  src: url('../fonts/Bogart-Bold-trial.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Loftype';
  src: url('../fonts/Loftype.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

:root {
  --primary-font-family: 'Bogart', monospace;
  --secondary-font-family: 'Loftype', monospace;
}
