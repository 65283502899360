@import 'reset';
@import 'colors';
@import 'typographies';
@import '@rainbow-me/rainbowkit/styles.css';

body,
button,
input {
  font-family: var(--primary-font-family);
  color: var(--primary-color);
}
